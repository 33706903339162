import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginGoogle() {
  const navigate = useNavigate()
  const responseGoogle = async (response) => {
    const getToken = await axios.get(`api/decodeUserGoogle?token=${response.credential}`)
    const { data } = getToken
    const userData = {
      id: `user-${new Date().getTime().toString()}`,
      userId: `user-${new Date().getTime().toString()}`,
      nickname:data.name.replace(' ', '-'),
      name:data.given_name,
      lastname:data.family_name,
      email: data.email,
      password: data.sub, // Usando o GoogleId como senha por enquanto
      phone: '',
      birthdate: '',
      followers: 0,
      likes: 0,
      profileImage: data.picture,
    };

    await axios.post('api/checkUser', { email: userData.email })
      .then(async res => {
        if (res.data.exists) {
          // Se o usuário já existir, faça login
          await axios.post('api/login', { email: userData.email, password: res.data.users[0].password},{
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(res => {
              if (res.data.success) {
                window.location.reload()
              } else {
                console.error('Falha no login');
              }a
            });
        } else {
          // Se o usuário não existir, crie um novo usuário
          await axios.post('api/register', userData,{
            headers: {
              'Content-Type': 'application/json'
            }
          })
            .then(async res => {
                await axios.post('api/login', { email: userData.email, password: res.data.user.password },{
                    headers: {
                      'Content-Type': 'application/json'
                    }
                })
                .then(res => {
                    window.location.reload()
                
                });
            });
        }
      });
  }

  return (
    <GoogleOAuthProvider clientId="651722734860-p6bemifqfjc8d0qsobmlm18d4i0ra51e.apps.googleusercontent.com">
      <div>
        <GoogleLogin  onSuccess={responseGoogle} onError={() => console.log('Login Failed')} 
        uxMode='redirect'
        logo_alignment= "center"
      redirectUri="/"
      text ={undefined} />
      
      </div>
    </GoogleOAuthProvider>
  );
}

export default LoginGoogle;
