import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import App from './App';
import './index.css';
import Register from './react-components/Register';
import MyProfile from './react-components/MyProfile';
import ValidateProdutcs from './react-components/ValidateProducts';
import TermosDeUso from './react-components/TermosDeUso';
import { CentralAjuda } from './react-components/CentralAjuda';
import { SobreNos } from './react-components/SobreNos';
import  {PoliticaPrivacidade}  from './react-components/PoliticaPrivacidade';
import Indica from './react-components/C-indica';
import ProductDetailsPage from './react-components/ProductDetailsPage';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Router>
    <Routes>
      <Route path="/register" element={<Register/>}/>
      <Route path="/my-profile" element={<MyProfile/>}/>
      <Route path="/validate-products" element={<ValidateProdutcs/>}/>
      <Route path="/termos-uso" element={<TermosDeUso/>}/>
      <Route path="/central-ajuda" element={<CentralAjuda/>}/>
      <Route path="/politica-privacidade" element={<PoliticaPrivacidade/>}/>
      <Route path="/sobre-nos" element={<SobreNos/>}/>
      <Route path="/indica" element={<Indica/>}/>
      <Route path="/pdp" element={<ProductDetailsPage/>}/>
      <Route path="/" element={<App/>}/>
    </Routes>
  </Router>
);