import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/timeline.css'
import './css/centralajuda.css'
import logo from './assets/logo.png';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CommentBox from './CommentBox';
function ProductDetailsPage() {
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coupom, setCoupom] = useState(null)
  const navigate = useNavigate()
  function goHome(){
    navigate('/')
    };
    const [userId, setUserId] = useState('');
  
    const openModal = (id, userid) => {
      setUserId(userid);
      setProductId(id)
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const search = window.location.search
    useEffect(() => {
      const fetchProduct = async () => {
        try {
          if(search.indexOf('productId') !== -1) {
          const res = await axios.get(`https://www.indikdo.com.br/api/get-product/${search.replace('?productId=','')}`);
          setProduct(res.data);
         }else{
          const res = await axios.get(`https://www.indikdo.com.br/api/get-product/${search.replace('?couponId=','')}`);
          setCoupom(res.data);
         }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      fetchProduct();
    }, []);
  if(coupom){
    return(
      <div className='pdp-container'>
      <img src={logo} alt="Logo" className="logo" onClick={goHome} style={{cursor:'pointer'}}/>
      <div className='timeline-items content-coupom'>
        <div className='coupon-info info-produc' style={{width:"850px"}}>
          <p className='coupon-title'>{coupom?.title}</p>
          <p className='coupon-description'>{coupom?.description}</p>
          <p className='coupon-description'>Site: {coupom?.site}</p>
          {coupom?.expirationDate && <p className='coupon-expiration'>Expira em: {coupom?.expirationDate}</p>}
          <p className='coupon-code'>Código: <b>{coupom?.code}</b>{<CopyToClipboard text={coupom?.code} options={{ message: "Copiado !", debug:true, format:"text" }}>
                    <button style={{ border: 'none', background: 'transparent' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
                      </svg>
                    </button>
                  </CopyToClipboard>}</p>
          <div className='interaction'>
            <button className='coments' onClick={() => openModal(coupom.id, coupom.userid)}>
              Ver comentários
            </button>
            </div>
            </div>
            </div>    
      {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            ariaHideApp={false}
            onRequestClose={closeModal}
            className={'modal-comments'}
            style={{
              content: {
                margin: 'auto',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <div style={{display:'flex', justifyContent:'end'}}>
              <button onClick={closeModal} className='material-symbols-outlined'>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                </svg>
              </button>
            </div>
            <CommentBox productId={productId} userId={userId}/>
          </Modal>
        )}
      </div>
      
    )
  }  
    

  return (
    <div className='pdp-container'>
    <img src={logo} alt="Logo" className="logo" onClick={goHome} style={{cursor:'pointer'}}/>
    {product && <div className='timeline-itens'>
      <div className='img-product'>
        <img style={{ objectFit: 'contain'}} src={product.image} alt={product.name} />
      </div>
      <div className='info-product'>
        <p className='product-name'>{product?.name?.length > 20 ? product?.name.substring(0, 50) + '...' : product.name}</p>
        <p className='description collapsed'>{product?.description?.length > 50 ? product.description.substring(0, 50) + '...' : product.description}</p>
        <p className='price'>Preço: {parseFloat(product.price.replace(/\./g, '').replace(/(\d{2})$/, '.$1')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <div className='interaction'>
          <button className='coments' onClick={() => openModal(product.id, product.userid)}>Ver comentários</button>
          <a href={product.link} target="_blank" rel="noopener noreferrer" className='ir-loja' onClick={()=>analytics()}>{product.siteName}</a>
        </div>
      </div>
    </div>}
    {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          ariaHideApp={false}
          onRequestClose={closeModal}
          className={'modal-comments'}
          style={{
            content: {
              margin: 'auto',
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <div style={{display:'flex', justifyContent:'end'}}>
            <button onClick={closeModal} className='material-symbols-outlined'>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
              </svg>
            </button>
          </div>
          <CommentBox productId={productId} userId={userId}/>
        </Modal>
      )}
    </div>
  );
}

export default ProductDetailsPage;
