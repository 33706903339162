import React from 'react';
import './css/termosdeuso.css'
import logo from './assets/logo.png';
import { useNavigate } from 'react-router-dom';
const TermosDeUso = () => {
    const navigate = useNavigate()
  function goHome(){
    navigate(-1);
    };
    return (
        <div className="termos-de-uso">
        <img src={logo} alt="Logo" className="logo" onClick={goHome} style={{cursor:'pointer', objectFit: 'contain'}} />
        <div className="container">
        <div style={{display:'flex', justifyContent:'center'}}>
        <h2>Termos de Uso e Serviços do Indikdo.com</h2>
        </div>
    
    <ol>
      <li>Participe da comunidade! Use os comentários para esclarecer dúvidas e adicionar informações úteis. Evite insultos e conflitos pessoais com outros usuários. Comentários que não estejam diretamente ligados ao produto do post podem ser excluídos.</li>
      <li>Divulgue uma promoção vantajosa Antes de sugerir uma promoção, certifique-se de que o produto realmente está com um preço inferior ao normal. Não promova nada que você mesmo não compraria. Apenas ofertas de e-commerces confiáveis e populares serão aceitas. Qualquer link encurtado ou de afiliado será modificado. Toda promoção enviada será moderada e sujeita à aprovação.</li>
      <li>Envio de fotos e atribuição de uma avaliação Todas as fotos enviadas devem ser de sua própria autoria. Qualquer foto que contenha conteúdo ofensivo ou spam será excluída. Use as tags para identificar o produto da foto. Uma boa maneira de começar é usando a sequência: #Nome #Marca #Modelo.</li>
      <li>Condições de uso Ao enviar um comentário, promoção ou avaliação, você concorda que todo material enviado poderá ser reproduzido pela Indikdo com a devida menção do autor. Reservamo-nos o direito de apagar ou editar qualquer conteúdo publicado na plataforma. Você, como autor, é o único responsável pelo conteúdo enviado.</li>
      <li>Responsabilidade Indikdo.com é um serviço de notícias 100% independente e não se responsabiliza pelas ofertas publicadas aqui. Para cada anúncio, tentamos obter a informação mais clara e completa possível e sempre que disponível, incluiremos a informação oficial de cada promoção e estabelecimento.</li>
      <li>Direitos Autorais Todos os logotipos e imagens mostrados aqui são propriedade exclusiva das empresas às quais se referem e são apresentados apenas para fins ilustrativos.</li>
      <li>Moderação Os editores e moderadores podem editar ou apagar o conteúdo publicado neste site a qualquer momento. Algumas das razões para isso podem ser a criação de ofertas ou o não respeito às regras do site e/ou do Chat.</li>
      <li>Privacidade Apenas os administradores têm acesso ao endereço de e-mail no Indikdo e este será compartilhado somente em cenários em que sejamos obrigados por lei a compartilhá-los com as autoridades competentes.</li>
    </ol>
  </div>
  <div className='div-btn'>
  <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={goHome}>Voltar</button>
  </div>
  <div className="footer" style={{width:'100%', display:'flex', justifyContent:'center'}}>
    <p>&copy; 2023 Indikdo.com</p>
  </div>

        </div>
    );
};

export default TermosDeUso;
