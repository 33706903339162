import React, { useContext, useEffect, useState } from 'react';
import { CategoryContext } from '../CategoryContext';
import { isMobile } from 'react-device-detect';
function CategorySelector() {
  const { selectedCategory, setSelectedCategory } = useContext(CategoryContext);
  const categories = ['Crianças & Família', 'Eletrônicos', 'Ferramentas', 'Mídias (Livros, Filmes, Músicas)', 'Nutrição', 'Para Casa', 'Saúde & Beleza', 'Serviços', 'Vestuário'];
  const [ isCoupon, setCoupon] = useState('')
  useEffect(()=>{
    if(window.localStorage.getItem('coupom')){
      setCoupon(window.localStorage.getItem('coupom'))
    }
  },[isCoupon])

  const itemsToShow = 5;
  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + 1, categories.length - itemsToShow));
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const isPrevDisabled = startIndex === 0;
  const isNextDisabled = startIndex + itemsToShow >= categories.length;

  const SvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 34 33" fill="none">
      <path d="M30.2099 16.3218C30.2099 12.8046 28.8127 9.43151 26.3257 6.94451C23.8387 4.45751 20.4656 3.06033 16.9485 3.06033C13.4313 3.06033 10.0582 4.45751 7.57122 6.94451C5.08422 9.43151 3.68704 12.8046 3.68704 16.3218C3.68704 19.8389 5.08422 23.212 7.57122 25.699C10.0582 28.186 13.4313 29.5832 16.9485 29.5832C20.4656 29.5832 23.8387 28.186 26.3257 25.699C28.8127 23.212 30.2099 19.8389 30.2099 16.3218ZM0.626709 16.3218C0.626709 11.993 2.34632 7.84146 5.40724 4.78053C8.46817 1.71961 12.6197 0 16.9485 0C21.2773 0 25.4288 1.71961 28.4897 4.78053C31.5506 7.84146 33.2702 11.993 33.2702 16.3218C33.2702 20.6506 31.5506 24.8021 28.4897 27.863C25.4288 30.9239 21.2773 32.6435 16.9485 32.6435C12.6197 32.6435 8.46817 30.9239 5.40724 27.863C2.34632 24.8021 0.626709 20.6506 0.626709 16.3218ZM20.1809 20.7274L10.9808 24.2659C9.74394 24.744 8.52619 23.5263 9.00436 22.2894L12.5429 13.0893C12.7533 12.5474 13.1741 12.1266 13.716 11.9162L22.9161 8.37765C24.153 7.89948 25.3708 9.11723 24.8926 10.3541L21.3541 19.5542C21.15 20.0962 20.7229 20.517 20.1809 20.7274ZM18.9887 16.3218C18.9887 15.7807 18.7737 15.2617 18.3911 14.8791C18.0085 14.4965 17.4896 14.2815 16.9485 14.2815C16.4074 14.2815 15.8884 14.4965 15.5058 14.8791C15.1232 15.2617 14.9083 15.7807 14.9083 16.3218C14.9083 16.8629 15.1232 17.3818 15.5058 17.7644C15.8884 18.147 16.4074 18.362 16.9485 18.362C17.4896 18.362 18.0085 18.147 18.3911 17.7644C18.7737 17.3818 18.9887 16.8629 18.9887 16.3218Z" fill="#FFA41C"/>
    </svg>
  );

  return (
    <>
     {!isCoupon && <>{isMobile ? (
        <div>
          <SvgIcon />
          <select className="category-dropdown" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value=""></option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div style={{display:'flex', alignItems:'center'}}>
        <button className='carousel-button btn-nextandprev' onClick={handlePrev} disabled={isPrevDisabled}>{'<'}</button>
        <div className="category-navigation">
       
        <div className='category-carousel-container'>
          
          <div className='category-carousel' style={{ transform: `translateX(-${startIndex * (100 / itemsToShow +10)}%)`, transition: 'transform 0.5s ease-in-out' }}>
            {categories.slice(startIndex, startIndex + itemsToShow).map((category) => (
              <button
                key={category}
                className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        
        </div>
        <button className='carousel-button btn-nextandprev' onClick={handleNext} disabled={isNextDisabled}>{'>'}</button>
        </div>
      )}</>}
    </>
  );
}

export default CategorySelector;
