import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import {Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './assets/logo.png';
import './css/myprofile.css'

const MyProfile = () => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [hasImage, setHasImage] = useState('');
  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInput = useRef(null);
  const [hover, setHover] = React.useState(false);
  const [promotionsCount, setPromotionsCount] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [newUserInfo, setNewUserInfo] = useState({ ...user });
  const handleImageClick = () => {
    fileInput.current.click();
  }

  useEffect(() => {

    fetchData()
  
  }, []);
  const fetchData = async () =>{
    const user = await JSON.parse(window.sessionStorage.getItem('user')) ;


    try {
      await axios.get(`api/getUserByEmail?email=${user.email}`)
        .then(async res => {

          
          setUser(res.data.user[0]);
          setNewUserInfo(res.data.user[0]);
          setImage(res.data.user[0].profileImage);
         const response = await axios.get(`api/getUserById?userId=${user.id}`)
         response.data.user.map(user => {
          if ('promotions' in user) {
            
            setPromotionsCount(user.promotions.product_id.length)
          }else{
            setPromotionsCount(0)

          }
      });
        });
    } catch (err) {
      console.log(err);
    }
}

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      setSelectedImage(reader.result);
      setHasImage('hasImage');
    };
  
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage("");
    }
  };
  const backToHome = ()=>{
    navigate('/')
  }
  const handleImageUpload = async () => {
    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('email', user.email);
  
    await axios.put(`api/updateUser`, formData,{
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async res => {
        setUser(res.data.user);
        fetchData()
        setHasImage('')
      })
      .catch(err => console.log(err));
  };
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setNewUserInfo({ ...newUserInfo, [event.target.name]: event.target.value });
  }; 
const logout = async () => {
  await axios.get(`api/logout`)
  .then(async res => {
    if(res.status ===200){
      window.sessionStorage.removeItem('user')
      
      navigate('/')
      window.location.reload()
    }
  })
}
const handleUpdateClick = async () => {
  try {
   await axios.put('api/updateUser', {...newUserInfo, email:user.email})    .then(async res => {
      fetchData()
    })
    .catch(err => console.log(err));
    setIsEditing(false);
  } catch (error) {
    console.error(error);
  }
};
const handleDeleteClick = async () => {
  // Mostrar Toast de confirmação
  toast.info(
    <>
      <div>Você tem certeza que deseja deletar seu perfil? Esta ação é irreversível.</div>
      <div className="btn-voltar" style={{display:'flex', justifyContent:'space-between', marginTop:'10px', flexDirection:'row'}}>
        <button onClick={() => handleDeleteProfile()}>Sim</button>
        <button onClick={() => toast.dismiss()}>Cancelar</button>
      </div>
    </>,
    {
      position: "top-center",
      autoClose: false, 
      closeButton: false, 
      hideProgressBar: true, 
      closeOnClick: false, 
      draggable: false,
      pauseOnHover: false, 
    }
  );
};


const handleDeleteProfile = async () => {
    await axios.delete(`api/deleteUser?email=${user.email}`).then(async res => {
          toast.success('Usuario deletado com sucess')
          logout()
     })
     .catch(err => console.log(err));
};
return (
  <>
    <div style={{display:'flex',justifyContent:'center'}}>
      <div className="logo">
        <button style={{ background:'transparent', border:'none'}} onClick={backToHome}>
          <img className="logo" src={logo} alt="logo"/>
        </button>
      </div>
    </div>
    <main>
    <div className={isMobile ? "user-profile-mobile" : "user-profile"}>
      {user && <div className={isMobile && 'perfil-mobile'} style={{ color: '#181818' }}>
      <section style={{display:'flex',justifyContent:'center'}}>
      <div 
        className="image-upload" 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
      >
        <img 
          src={image ? `data:image/jpeg;base64,${image}` : 'https://i.stack.imgur.com/EYX0L.png' } alt="User" 
          onClick={handleImageClick}
        />
        {hover && <div className="overlay">Alterar imagem</div>}
        <input 
          type="file" 
          className="file-input"
          ref={fileInput} 
          onChange={handleImageChange} 
        />
      </div>
     
      <button style={{ caretColor: 'rgb(255,164,28)' , display:'none'}} onClick={handleImageUpload} className={hasImage}>Alterar imagem</button>
      </section>
      <section>
        <h1  style={{textAlign:'center'}}>Meus Dados</h1>
        <div style={{display:'flex', flexDirection:'column'}}>
          <label>Email: </label>
          {isEditing ? <input type="email" name="email" value={newUserInfo.email} onChange={handleInputChange} /> : <p style={{textAlign:'start'}}>{user.email}</p>}
          <label>Nome: </label>
          {isEditing ? <input type="text" name="firstName" value={newUserInfo.name} onChange={handleInputChange} /> : <p  style={{textAlign:'start'}}>{user.name}</p>}
          <label>Sobrenome: </label>
          {isEditing ? <input type="text" name="lastName" value={newUserInfo.lastname} onChange={handleInputChange} /> : <p  style={{textAlign:'start'}}>{user.lastname}</p>}
          <label>Nickname: </label>
          {isEditing ? <input type="text" name="nickname" value={newUserInfo.nickname} onChange={handleInputChange} /> : <p  style={{textAlign:'start'}}>{user.nickname}</p>}
          <label>Telefone: </label>
          {isEditing ? <input type="tel" name="phone" value={newUserInfo.phone} onChange={handleInputChange} /> : <p  style={{textAlign:'start'}}>{user.phone}</p>}
          <label>Data de Aniversário: </label>
          {isEditing ? <input type="date" name="birthdate" value={newUserInfo.birthdate} onChange={handleInputChange} /> : <p  style={{textAlign:'start'}}>{user.birthdate}</p>}
        </div>
        <div className="btn-voltar" style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
        <button onClick={handleEditClick}>{isEditing ? 'Cancelar' : 'Editar'}</button>
        {isEditing && <button onClick={handleUpdateClick}>Atualizar</button>}
        </div>
        </section>
      
        <section style={{paddingTop:"65px"}}>
        <h1 style={{textAlign:'center'}}>Limite de Produtos Adicionados</h1>
        <div>
          {((promotionsCount / 30) * 100) > 100 ? (
            <p>Você já atingiu a meta de promoções, caso ainda não tenha recebido seu pagamento, por favor entre em contato no email <b><u>indikdo@gmail.com</u></b></p>
          ) : ((promotionsCount / 30) * 100) === 100 ? (
            <p>Parabéns, você atingiu a meta, por favor entre em contato com o email <b><u>indikdo@gmail.com</u></b> para mais informações e receber seu pagamento</p>
          ) : (<><p>Se você atingir a meta de 30 promoções inseridas no mês, <b><u>ganhará R$xx,xx</u></b> por promoções inserido.</p>
            <div className="progress-bar" style={{ height: '20px', width: '100%', backgroundColor: '#ccc', borderRadius: '15px', position: 'relative' }}>
              <div style={{ height: '100%', width: `${(promotionsCount / 30) * 100}%`, backgroundColor: '#FFA41C', borderRadius: '15px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff' }}>
                <b>{((promotionsCount / 30) * 100).toFixed(1)}%</b>
              </div>
            </div></>)}
        </div>
        </section>
        <section style={{paddingTop:"65px"}}>
        <button onClick={handleDeleteClick}>Deletar Perfil</button>
        
      <div className="btn-voltar" style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
        <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={logout}>Sair</button>
        <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={backToHome}>Voltar</button>
      </div>
      </section>
      </div>}
    </div>
    <ToastContainer position='top-center'/>
    
    </main>

  </>
);
};

export default MyProfile;