// @flow 
import * as React from 'react';
import './css/centralajuda.css'
import logo from './assets/logo.png';
import { useNavigate } from 'react-router-dom';
export const CentralAjuda = () => {
    const navigate = useNavigate()
    function goHome(){
      navigate('/')
      };
    return (
        <div className='central-ajuda'>
         <img src={logo} alt="Logo" className="logo" onClick={goHome} style={{cursor:'pointer'}}/>
             <div className="container">
    <h2>O que é o Indikdo?</h2>
    <p>O Indikdo é uma comunidade online de pessoas que adoram economizar. Aqui, você pode encontrar promoções, descontos, erros e avaliações sobre diversos produtos e serviços. Você também pode publicar suas próprias ofertas, comentar as dos outros.</p>
    <p>O Indikdo foi criado em 2023 com a missão de ajudar as pessoas a gastarem menos e terem uma boa experiência de compra. Temos uma equipe dedicada que verifica cada publicação.</p>
    
    <h2>Como usar o Indikdo para encontrar as melhores ofertas</h2>
    <p>O Indikdo é uma plataforma social de compras, onde você pode descobrir, compartilhar e aproveitar as melhores ofertas da internet. Neste artigo, vamos explicar como você pode usar o Indikdo para economizar e se divertir.</p>
    
    <h2>Como se cadastrar no Indikdo?</h2>
    <p>Você pode efetuar um cadastro clicando em cadastrar-se e preenchendo os dados solicitados. Ou pode logar com uma conta google.</p>
    
    <h2>Como publicar ofertas no Indikdo?</h2>
    <ul>
      <li>Esteja logado na plataforma;</li>
      <li>Clique em "Publicar" (botão com sinal de +) no site;</li>
      <li>Preencha as informações da oferta, como descrição, link, preço;</li>
      <li>Clique em "Enviar" e aguarde a aprovação da nossa equipe.</li>
    </ul>
    
    <h2>Como interagir com a comunidade do Indikdo?</h2>
    <p>Para interagir com a comunidade do Indikdo, você pode usar as seguintes ações:</p>
    <ul>
      <li>Comentar: deixe seu feedback, sua opinião ou sua dúvida sobre uma oferta ou um produto;</li>
    </ul>
    
    <h2>Como entrar em contato com o suporte do Indikdo?</h2>
    <p>Se você precisar de ajuda, você pode entrar em contato com o suporte do Indikdo pelos seguintes canais:</p>
    <ul>
      <li>E-mail: envie um e-mail para indikdocontato@gmail.com, e receba uma resposta da nossa equipe em até 1 dia útil;</li>
    </ul>
    <p> </p>
    <p>Quando você compra por meio de links em nosso site, podemos ganhar uma comissão de afiliado sem nenhum custo para você.</p>
  </div>
  <div className='div-btn'>
  <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={goHome}>Voltar</button>
  </div>
  <div className="footer" style={{width:'100%', display:'flex', justifyContent:'center'}}>
    <p>&copy; 2023 Indikdo.com</p>
  </div>
        </div>
    );
};