import React, { useState, useEffect, memo, useContext  } from 'react';
import Modal from 'react-modal';
import CommentBox from './CommentBox';
import axios from 'axios';
import './css/timeline.css';
import { CategoryContext } from '../CategoryContext';
import { getProducts, setProducts } from './IndexDB';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
const ProductItem = memo(({ product, openModal, userId }) => {
  const analytics = () =>{
    window.dataLayer.push({
      'user_id': userId,
      'product_id': product.id,
      'event':'clicklink'
    });
  };

  const [isShareOpen, setIsShareOpen] = useState(false);

  const toggleShare = () => {
    setIsShareOpen(!isShareOpen);
  };
  const shareContent = () => {
    if (isMobile) {
      navigator.share({
        title: `Aproveite essa incrivel promoção => ${product.name} => Clique no link e confira `,
        url: `/pdp?productId=${product.id}`,
      })
      .then(() => console.log('Conteúdo compartilhado!'))
      .catch((error) => console.log('Erro ao compartilhar', error));
    } else {
      toggleShare();
    }
  };
  return (
    <div className='timeline-itens'>
      <div className='img-product'>
        <img style={{ objectFit: 'contain'}} src={product.image} alt={product.name} />
      </div>
      <div className='info-product'>
        <p className='product-name'>{product?.name?.length > 20 ? product?.name.substring(0, 50) + '...' : product.name}</p>
        <p className='description collapsed'>{product?.description?.length > 50 ? product.description.substring(0, 50) + '...' : product.description}</p>
        <p className='price'>Preço: {parseFloat(product.price.replace(/\./g, '').replace(/(\d{2})$/, '.$1')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
        <div className='interaction'>
  <button className='coments' onClick={() => openModal(product.id, product.userid)}>Ver comentários</button>
  <div className='div-share '>
  {isShareOpen && (
    <div className='share-dropdown share-balloon'>
      <FacebookShareButton url={`www.indikdo.com.br/pdp?productId=${product.id}`} quote={`Aproveite essa incrivel promoção => ${product.name} => Clique no link e confira `}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <WhatsappShareButton url={`www.indikdo.com.br/pdp?productId=${product.id}`} title={`Aproveite essa incrivel promoção => ${product.name} => Clique no link e confira `}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TwitterShareButton url={`www.indikdo.com.br/pdp?productId=${product.id}`} title={`Aproveite essa incrivel promoção => ${product.name} => Clique no link e confira `}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <CopyToClipboard text={`www.indikdo.com.br/pdp?productId=${product.id}`}>
        <button style={{border:'none', background:'transparent'}}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z"/></svg></button>
      </CopyToClipboard>
    </div>
  )}
  {!isShareOpen && ( <button onClick={shareContent} style={{border:'none', background:'transparent', color:'#FFA41C', fontWeight:'bolder',letterSpacing:'4px'}}>Indike<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
  <path fill="#FFA41C" d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"/>
</svg>
</button>
  )}</div>
  
  <a href={product.link} target="_blank" rel="noopener noreferrer" className='ir-loja' onClick={()=>analytics()}>{product.siteName}</a>
</div>
      </div>
    </div>
  );
});

function Timeline() {
  const [products, setProductsState] = useState([]);
  const { selectedCategory } = useContext(CategoryContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productId, setProductId] = useState('');
  const [visibleProducts, setVisibleProducts] = useState(20);
  const [userId, setUserId] = useState('');
  
  const openModal = (id, userid) => {
    setProductId(id);
    setUserId(userid);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  
  useEffect(()=>{
    const user = JSON.parse(window.sessionStorage.getItem('user'))

    if(user){
      setUserId(user.id)
    }

  },[userId])
  const loadMore = () => {
    // Increase the number of visible products by 20
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 20);
  };
  useEffect(() => {
    async function fetchProducts() {
      try {
        const storedProducts = await getProducts();
        if (storedProducts.length > 0) {
          setProductsState(storedProducts);
        } else {
          const response = await axios.get('api/getTimeline');
          await setProducts(response.data);
          setProductsState(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    }
    fetchProducts();
  }, []);

  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          ariaHideApp={false}
          onRequestClose={closeModal}
          className={'modal-comments'}
          style={{
            content: {
              margin: 'auto',
              padding: '20px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <div style={{display:'flex', justifyContent:'end'}}>
            <button onClick={closeModal} className='material-symbols-outlined'>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
              </svg>
            </button>
          </div>
          <CommentBox productId={productId} userId={userId}/>
        </Modal>
      )}
      <div className='timeline-content'>
        <div className='timeline-columm'>
          {products
            .filter(
              (product) =>
                product.accepted &&
                (product.tag === selectedCategory || selectedCategory === null ) && product.category != "coupons"
            )
            .sort((a, b) => b._ts - a._ts)
            .slice(0, visibleProducts) // Display only the first `visibleProducts` items
            .map((product, index) => (
              <ProductItem key={product.id} product={product} openModal={openModal} userId={userId} />
            ))}
        </div>
      </div>
      <div style={{display:'flex', justifyContent:'center'}}>
      {visibleProducts < products.length && (
        <button onClick={loadMore} className='load-more-button'>
          Ver mais 
        </button>
      )}      
      </div>
    

    </div>
  );
}

export default Timeline;
