import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './css/headers.css'
import { Link } from 'react-router-dom';
import logo from './assets/logo.png';
import postar from './assets/postar.png';
import SearchBar from "./SearchBar";
import Modal from 'react-modal';
import LoginGoogle from './logins/LoginGoogle';
import axios from 'axios';
import ProductInputPage from './AddProduct';
import CouponInputPage from "./AddCoupom";
import { isMobile } from 'react-device-detect';
function Header(){
  const [showPassword, setShowPassword] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [close , setClose] = useState(false)
    const [email, setUseremail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [authLogin, setAuthLogin] = useState([]);
  const [emailError, setEmailError] = useState('');
const [passwordError, setPasswordError] = useState('');
const [modalIsOpen, setModalIsOpen] = useState(false);
 const [selectedPage, setSelectedPage] = useState('');

  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    setModalIsOpen(true);
  };
  const handleUseremailChange = (e) => {
    if (e.target.value ===''){
      setEmailError('')
    }
    setUseremail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    if (e.target.value ===''){
      
      setPasswordError('')
    }
    setPassword(e.target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
const checkCookie = async () => {
  try {
    const response = await axios.get(`api/checkCookie`);
  
    if (response.status === 200) { 
      setAuthLogin(response.data.user);
      setUsername(response.data.user.nickname)
      window.sessionStorage.setItem('user', JSON.stringify(response.data.user))
    }
  } catch (error) {
    setAuthLogin([]);
      setUsername('')
    console.error(error.message);
  }
};
useEffect(()=>{
  let sessionUser = JSON.parse(window.sessionStorage.getItem('user'))
  if(!sessionUser){
    checkCookie();
  }else{
    setAuthLogin(sessionUser);
    setUsername(sessionUser.nickname)
  }
  
},[])
const  handleSubmit = async (e) => {
  e.preventDefault();
  setEmailError('');
  setPasswordError('');
  await axios.post(`api/login`, { email, password }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    if(res.status === 200){
      window.sessionStorage.setItem('user', JSON.stringify(res.data.user))
      checkCookie();
      window.location.reload();
    }
  })
  .catch((error) => {
      if (error.response) {
          if (error.response.data === 'E-mail inválido') {
              setEmailError('E-mail inválido');
          } else if (error.response.data === 'Senha inválida') {
              setPasswordError('Senha inválida');
          }
      }
  });
};
    useEffect(()=>{
      if(close == true){
        setIsModalOpen(false)
        setClose(false)
      }
    },[isModalOpen, close])
  const openModal = () => {
    setIsModalOpen(true);
    
  };
  const closeModal = () => {
    setClose(true)
    setIsModalOpen(false);
  }
  const signOut = async () => {
    await axios.get(`/api/logout`)
    .then(async res => {
      if(res.status ===200){
        window.sessionStorage.removeItem('user')
        window.location.reload()
      }
    })
  }
    return(
        <>
          
            <div className="header">
            <div><h1></h1></div>
                <div className="logo">
                    <img className="logo" src={logo} alt="logo"/>
                    <Link to="/"></Link>
                </div>
                <div className="container-menu-bar">
                
                <div className="search-bar">
                         <SearchBar/>
                </div>
                {isMobile ? '': 
                <>
                {authLogin.length !=0 ?  <div style={{width:'3.751125rem',height:'2.8125rem'}}>
                <img style={{width:'50px',height:'50px'}} src={postar} className="image-postar" />  
        <select onChange={handlePageChange} style={{opacity: 0, width: '100%', height: '100%', position:'relative', left:'2px',bottom:'48px'}}>
          <option value="ProductInputPage">Promoção</option>
          <option value="CouponInputPage">Cupom</option>
        </select>
        {selectedPage === 'ProductInputPage' &&  <ProductInputPage isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} />}
      {selectedPage === 'CouponInputPage' && <CouponInputPage isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} />}
           
                </div>: ''}
                <div className="menu-profile">
                    <div className="selfmenu">
                    <button onClick={openModal} style={{border: 'none', backgroundColor: 'transparent'}} title="login">
                    {authLogin.length == 0 ? (<Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      ariaHideApp={false}
                      className='modal-login'
                      style={{
                        content: {
                          width: '400px',
                          margin: 'auto',
                          padding: '20px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        },
                        overlay: {
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                    <div className="btn-modal-close">
                    <button onClick={closeModal}>x</button>
                    </div>
                      <div className="modal-title">
                        <div className="modal-title-text"><b>
                          Descubra as melhores <br/>
                          promoções!
                        </b></div>
                        <div className="modal-sub-title-text">
                        Entre gratuitatamente e comece a economizar!
                        </div>
                      </div>
                        <div className="form-login">
                          <form className="input-login">
                          <div style={{display:'flex', flexDirection:'column'}}>
                          <input
                            type="text"
                            id="email"
                            value={email}
                            onChange={handleUseremailChange}
                          />
                          {emailError && <p className="error">{emailError}</p>}
                          </div>
                          <div style={{display:'flex', flexDirection:'column'}}>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                              />
                                    <button type="button" id="togglePassword" onClick={togglePasswordVisibility} aria-label="Toggle password visibility">
                <FontAwesomeIcon icon={showPassword ?faEye :  faEyeSlash } />
            </button>
                            {passwordError && <p className="error">{passwordError}</p>}  
                            </div>         
                             <button type="submit" onClick={handleSubmit}>Login</button>
                          </form>
                         <p>não possui login? <a href="/register">cadastre-se</a></p>
                        </div>
                        <div className="hr">
                          <hr /><p>ou</p> <hr />
                        </div>
                        
                      <div className="div-login-apps">
                      
                        <LoginGoogle />
                      {/*  <AppleSignInAuth />  <LoginFacebook />*/}
                      </div>
                      <div className="termos" style={{display:'flex', flexDirection:'row', justifyContent:"space-around", width:"100%"}}>
                      <Link to="/central-ajuda">Central de Ajuda</Link>
                      <Link to="/termos-uso">Termos de serviço</Link>
                      <Link to="/sobre-nos">Sobre nós</Link>
                    </div>
                    </Modal>): (<Modal
                      isOpen={isModalOpen}
                      onRequestClose={closeModal}
                      ariaHideApp={false}
                      className='modal-logado'
                      style={{
                        content: {
                          width: '300px',
                          margin: 'auto',
                          padding: '20px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        },
                        overlay: {
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                      }}
                    >
                    <div className="btn-modal-close">
                    <button onClick={closeModal}>x</button></div>
                    <div className="user-logged">
                      <p className="username">@{username}</p>
                      <p className="my-accounts-link"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
  <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 15.0141 20.6665 17.7167 18.5573 19.5501C18.0996 18.3251 17.306 17.2481 16.2613 16.4465C15.0388 15.5085 13.5409 15 12 15C10.4591 15 8.96118 15.5085 7.73867 16.4465C6.69405 17.2481 5.90038 18.3251 5.44269 19.5501C3.33349 17.7167 2 15.0141 2 12ZM16.8296 20.7059C16.8337 20.7212 16.8381 20.7363 16.8429 20.7512C15.4081 21.5469 13.757 22 12 22C10.243 22 8.59193 21.5469 7.15711 20.7512C7.16185 20.7363 7.16628 20.7212 7.17037 20.7059C7.45525 19.6427 8.08297 18.7033 8.95619 18.0332C9.82942 17.3632 10.8993 17 12 17C13.1007 17 14.1706 17.3632 15.0438 18.0332C15.917 18.7033 16.5448 19.6427 16.8296 20.7059ZM10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9ZM12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5Z" fill="#FFA41C"/>
  <rect x="2.5" y="2.5" width="19" height="19" rx="9.5" stroke="#FFA41C"/>
</svg> <Link to="/my-profile">Meu Perfil</Link></p>
                    </div>
                    <div>
                      <button type="button" className="sing-out" onClick={signOut}>Sair</button>
                    </div>
                    <hr />
                    <div className="termos">
                      <Link to="/central-ajuda">Central de Ajuda</Link>
                      <Link to="/termos-uso">Termos de serviço</Link>
                      <Link to="/sobre-nos">Sobre nós</Link>
                    </div>
                    <div className="redes">
                          <b>Rede Sociais</b>
                          <div className="right">
                          
                          <a href="https://www.facebook.com/indikdoPage" title="Facebook"><i className="fab fa-facebook-f" style={{color:'#fd7e14'}}></i></a>
                          <a href="https://www.instagram.com/indikdopromo/"title="Instagram"><i className="fab fa-instagram" style={{color:'#fd7e14'}}></i></a>
                          <a href="https://www.twitter.com/indikdoPromo" title="Twitter"><i className="fab fa-twitter" style={{color:'#fd7e14'}}></i></a>

                      </div>
                      </div>
                    </Modal>) }
                    <div className="svg-login"></div>
                   
</button>
                    </div>
                </div>
                </>}
               
                </div>
               
            </div>
        </>
    )
}

export default Header;