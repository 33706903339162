import React, { useEffect, useState } from 'react';
import Timeline from './react-components/Timeline';
import TimelineCupom from './react-components/TimelineCupom';
import Menu from './react-components/Menu';
import Header from './react-components/Header';
import Footer from './react-components/Footer';
import CategorySelector from './react-components/CategorySelector';
import { CategoryContext } from './CategoryContext';
import HeaderMobile from './react-components/HeaderMobile';
import { isMobile } from 'react-device-detect';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

function App() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ isCoupon, setCoupon] = useState('')
  useEffect(()=>{
    if(window.localStorage.getItem('coupom')){
      setCoupon(window.localStorage.getItem('coupom'))
    }
  },[isCoupon])

  return (
    <CategoryContext.Provider value={{ selectedCategory, setSelectedCategory }}>
      <div>
        <div className='container-menu'>
          <Header />
          <Menu />
          {isMobile ? '' : <CategorySelector />}
        </div>
        <div className='timeline'>
          {isCoupon ? <TimelineCupom/> :<Timeline />}
        </div>
        {isMobile && <div className='container-header-mobile'>
          <HeaderMobile/>
        </div>}
        <Footer />
        
      </div>
    </CategoryContext.Provider>
  );
}

export default App;
