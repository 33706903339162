import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
const InstructionalVideoModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
        console.log('Modal')
      setIsModalOpen(true);
    }
  }, [isModalOpen]);

  const handleClose = () => {
    sessionStorage.setItem('hasSeenModal', 'true');
    setIsModalOpen(false);
  };

  const handlePlay = () => {
    sessionStorage.setItem('hasSeenModal', 'true');
  };
  console.log('Play',isModalOpen);
  return (
    <>
     <div>
     {isModalOpen && (
      <Modal className="modal-comments"
             isOpen={true}
            onRequestClose={handleClose}
            ariaHideApp={false}
            style={{
                paddingTop:'20px',
                content: {
                    width: '660px',
                    margin: 'auto',
                    padding: '20px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    display:'flex',
                    alignItems: 'center',
                    justifyContent:'center',
                    flexDirection: 'column',
                    marginTop:'200px'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                }}
        >
        <div style={{
           display:'flex',
        alignItems: 'center',
        justifyContent:'center',
        flexDirection: 'column'
        }}>
                <div style={{display:'flex', justifyContent:"flex-end", width:'100%'}}> <button style={{background:'transparent', border:'none'}} onClick={handleClose}>X</button></div>
                 <p>Este é um vídeo de instrução de como funciona o site.</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/LlUDNsLXik4"
            title="Vídeo de instrução"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            onPlay={handleClose} 
          ></iframe>
          
        </div>
         
        </Modal>)}
        </div>
      <button className='' style={{fontSize:'16px !important'}} onClick={() => setIsModalOpen(true)}>Entenda Nosso Site</button>
    </>
  );
};

export default InstructionalVideoModal;
