import React,{useEffect, useState} from 'react';
import './css/searchbar.css'
import axios from 'axios';
const ProductPreview = ({ product, productCatalog }) => {
 if(!product && !productCatalog){
  return null
 }


  return (
    <div className="product-preview">
      <div className="dropdown-content">
        {product ? product.map(product =>(
          <div key={product.id} style={{display:'flex', flexDirection:'row'}}>
          <div className='img-product'>
              <img style={{ objectFit: 'contain'}} src={product.image} alt={product.name} />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
          <p className='product-name'><a href={product.link} target="_blank">{product?.name?.length > 20 ? product?.name.substring(0, 50) + '...' : product.name}</a></p>
          <p className='price'>{parseFloat(product.price.replace(/\./g, '').replace(/(.*)(\d{2})$/, "$1,$2")).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
          </div>
          </div>
        )):productCatalog.map(product =>(
          <div key={product.id} style={{display:'flex', flexDirection:'row'}}>
          <div className='img-product'>
              <img style={{ objectFit: 'contain'}} src={product.imagem} alt={product.name} />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
          <p className='product-name'>{product?.name?.length > 20 ? product?.name.substring(0, 50) + '...' : product.name}</p>
          <p className='product-sku'>SKU: <b>{product?.sku}</b></p>
          <div style={{display:"flex", alignItems:'center', justifyContent:'center'}}>
          <a href={product.link} target="_blank" className='catalog-link'>Ir Para Loja</a>
          </div>
          </div>
         
          </div>
        ))}
      
      </div>
    </div>
  );
};
const searchbar = ()=> {
    const [searchTerm, setSearchTerm] = useState('');
  const [showInput, setShowInput] = useState(false);
  const [product, setProduct] = useState(null);
  const [productCatalog, setProductCatalog] = useState(null);
  const [pathName, setPathName] = useState(null);
  const handleSearchChange = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if(pathName != '/indica'){
      if (term.length >= 3) {
        const response = await axios.get(`/api/search?name=${term}`);
        const products = await response.data;
        if (products.length > 0) {
          setProduct(products); 
        }
      } else if (term.length === 0) {
        setProduct([]); 
      }
    }else{
      if (term.length >= 3) {
        const response = await axios.get(`/api/searchCatalog?name=${term}`);
        const catalogProduct = await response.data;
        if (catalogProduct.length > 0) {
          setProductCatalog(catalogProduct); 
        }
      } else if (term.length === 0) {
        setProductCatalog([]); 
      }
    }
    
  };
  useEffect(()=>{
    setPathName(document.location.pathname)
  },[product])

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchTerm('');
  };

  const toggleInput = () => {
    if (showInput) {
    }
  
    setShowInput(prevShowInput => !prevShowInput);
    setSearchTerm(prevSearchTerm => showInput ? '' : prevSearchTerm);
  };
  

    return (
      <form onSubmit={handleSearchSubmit}>
        <div className="search-bar">
          {showInput ? (
            <><div className='input-bar'>
              <input
                type="text"
                placeholder="   Pesquisar..."
                value={searchTerm}
                onChange={handleSearchChange} />
                <ProductPreview product={product} productCatalog={productCatalog} />
            </div> </>
          ) : (
            <div className='menuSpace'></div>
          )}
          <button type="button" className='btn-search' onClick={toggleInput}>
            {<div className='search-icon'><svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame 1">
<circle cx="25" cy="25" r="23" stroke="#FFA41C" strokeWidth="2" fill="none"/>

<path id="Vector" d="M29.967 22.5643C29.967 20.4091 29.144 18.3421 27.679 16.8181C26.214 15.2941 24.2271 14.4379 22.1553 14.4379C20.0835 14.4379 18.0965 15.2941 16.6315 16.8181C15.1665 18.3421 14.3435 20.4091 14.3435 22.5643C14.3435 24.7196 15.1665 26.7865 16.6315 28.3105C18.0965 29.8345 20.0835 30.6907 22.1553 30.6907C24.2271 30.6907 26.214 29.8345 27.679 28.3105C29.144 26.7865 29.967 24.7196 29.967 22.5643ZM28.4584 30.8481C26.73 32.2753 24.5378 33.1286 22.1553 33.1286C16.5455 33.1286 12 28.4001 12 22.5643C12 16.7285 16.5455 12 22.1553 12C27.7651 12 32.3105 16.7285 32.3105 22.5643C32.3105 25.0429 31.4903 27.3233 30.1183 29.1213L36.6558 35.9221C37.1147 36.3995 37.1147 37.1715 36.6558 37.6438C36.1969 38.1162 35.4547 38.1213 35.0007 37.6438L28.4584 30.8481Z" fill="#FFA41C"/>
</g>
</svg></div>}
          </button>
        </div>
      </form>
    );
}


export default searchbar;