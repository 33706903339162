import React, { useEffect, useState } from 'react';
import './css/register.css'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [nickname, setNickname] = useState(''); 
  const [name, setName] = useState(''); 
  const [lastname, setLastname] = useState('');
  const navigate = useNavigate();

  const isPasswordConfirmed = () => {
    return password === confirmPassword;
  };

  useEffect(() => {
    const passwordsMatch = password === confirmPassword;
    setConfirmPasswordError(passwordsMatch ? '' : 'As senhas não coincidem');
  }, [password, confirmPassword]);

  // Função para validar o email
  const isEmailValid = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Função para validar o celular
  const isPhoneValid = (phone) => {
    const phonePattern = /^[1-9]{2}[0-9]{9}$/;
    return phonePattern.test(phone);
  };

  const isRegisterButtonEnabled = () => {
    const emailValid = isEmailValid(email);
    const passwordConfirmed = isPasswordConfirmed();
    const phoneValid = isPhoneValid(phone);

    return (
      nickname.trim() !== '' &&
      name.trim() !== '' &&
      lastname.trim() !== '' &&
      email.trim() !== '' &&
      password.trim() !== '' &&
      dateOfBirth.trim() !== '' &&
      emailValid &&
      acceptTerms &&
      phoneValid &&
      passwordConfirmed
    );
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(isEmailValid(e.target.value) ? '' : 'Email inválido');
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(password === e.target.value ? '' : 'As senhas não coincidem');
  };

  const handleDateOfBirthChange = (e) => {
    let input = e.target.value;
    let formattedInput = input.replace(/(\\d{2})(\\d{2})(\\d{4})/, "$1/$2/$3");
    setDateOfBirth(formattedInput);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setPhoneError(isPhoneValid(e.target.value) ? '' : 'Telefone inválido');
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastname(e.target.value);
  };

  const handleRegister = async () => {
    if (!isRegisterButtonEnabled()) {
      alert('Por favor, preencha todos os campos corretamente e aceite os termos e condições.');
      return;
    }

    try {
      const response = await axios.post(`api/register`, {
        nickname,
        name,
        lastname,
        email,
        password,
        phone,
        birthdate: dateOfBirth,
        profileImage: '',
      },{  headers: {
        'Content-Type': 'application/json'
      }});

      if (response.status === 201) {
        const loginResponse = await axios.post(`api/login` , {
          email,
          password,
        });

        if (loginResponse.status === 200) {
          navigate('/');
        } else {
          throw new Error('Login failed');
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="register-container" style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh' , backgroundColor:'#f0f0f0'}}>
      <div className={`register ${isMobile && 'register-mobile'}`} >
        <h1>Cadastra-se</h1>
        <input
          type="text"
          placeholder="Apelido"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
          required // Campo obrigatório
        />
        <div>
          <input
            type="text"
            placeholder="Nome"
            value={name}
            onChange={handleNameChange}
            required // Campo obrigatório
          />
          <input
            type="text"
            placeholder="Sobrenome"
            value={lastname}
            onChange={handleLastNameChange}
            required // Campo obrigatório
          />
        </div>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          className={emailError ? 'invalid' : ''}
          required // Campo obrigatório
        />
        {emailError && <span className="error-message">{emailError}</span>}
        <div className='password'>
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            className={passwordError ? 'invalid' : ''}
            required // Campo obrigatório
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className={confirmPasswordError ? 'invalid' : ''}
            required // Campo obrigatório
          />
       
        </div>
        <div style={{display:'flex', justifyContent:'end'}}>
        {confirmPasswordError && <span className="error-message">{confirmPasswordError}</span>}
        </div>
        
        <div className='others'>
          <input
            type="date"
            placeholder="Date of Birth (YYYY-MM-DD)"
            value={dateOfBirth}
            onChange={handleDateOfBirthChange}
            required // Campo obrigatório
          />
     
          <input
            type="text"
            placeholder="Phone"
            value={phone}
            onChange={handlePhoneChange}
            className={phoneError ? 'invalid' : ''}
            required // Campo obrigatório
          />
           <div style={{display:'flex', justifyContent:'end'}}>
           {phoneError && <span className="error-message">{phoneError}</span>}
           </div>
         
        
        </div>
        <div className='terms'>
          <label>
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              required // Campo obrigatório
            />
          <a href="/termos-uso" alt="_blank">Termos de serviço</a>
          </label>
        </div>
        <div className='btn-register'>
          <button onClick={handleRegister} disabled={!isRegisterButtonEnabled()}>
          Cadastra-se
          </button>
        </div>
      </div>
    </div>
  );
}

export default Register;
