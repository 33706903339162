import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";
import axios from "axios";
import {deleteDatabaseCoupoms} from "./IndexDB"
import "./css/addproduct.css";

const CouponInputPage = ({ isOpen, onRequestClose }) => {
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [coupomSite, setCoupomSite] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponTitle, setCouponTitle] = useState("");
  const navigate = useNavigate();

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleExpirationDateChange = (e) => {
    setExpirationDate(e.target.value);
  };

  const handleCoupomSiteChange = (e) => {
    setCoupomSite(e.target.value);
  };

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };
  
  const handleCouponTitleChange = (e) => {
    setCouponTitle(e.target.value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const responseCheckPromise = await JSON.parse(window.sessionStorage.getItem('user'))

      const couponInfo = {
        description,
        userId: responseCheckPromise.id,
        category: "coupons",
        title: couponTitle,
        site: coupomSite,
        code: couponCode,
        expirationDate: expirationDate,
      };
      let couponInfoPromise = axios.post(`api/addProducts`, couponInfo, {
        headers: { "Content-Type": "application/json" },
      });

      Promise.all([couponInfoPromise, responseCheckPromise]).then(
        async ([couponRes, userRes]) => {
          const  user  = userRes;
          const firstComment = {
            id: `comments-${Date.now().toString()}`,
            text: description,
            timestamp: new Date().toISOString(),
            likes: 0,
            userId: user.id,
            userName: user.nickname,
            productId: couponRes.data.id,
            category: "comments",
          };

          await axios.post("api/comments", firstComment, {
            headers: { "Content-Type": "application/json" },
          });

          onRequestClose();
          navigate(`/`);
          deleteDatabaseCoupoms()
          location.reload(true);
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      );
    } catch (error) {
      toast.error("O link inserido da promoção não é válido em nosso site.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      className={isMobile ? "modal-product-mobile" : "modal-login"}
      style={{
        content: {
          width: "500px",
          margin: "auto",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <h2>Inserir Cupom</h2>
      <p className="watch-out">
        <b>ATENÇÃO!!</b> Os cupons levarão no máximo 15 minutos para refletir
        no site, devido a validação das informações.
      </p>
      <form onSubmit={handleSubmit} style={{width:'100%'}}>
        <div className="info-promo" style={{flexDirection:'column'}}>
          <div style={{display:'flex', flexDirection:'column'}}>
            <label style={{ marginLeft: 10 }}>Titulo do Cupom</label>
            <input
              type="text"
              value={couponTitle}
              placeholder="Cupom Desconto R$ 10"
              style={{width:'224px', textAlign:'start'}}
              onChange={handleCouponTitleChange}
              required
            />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <label style={{ marginLeft: 10 }}>Código do Cupom</label>
            <input
              type="text"
              style={{ width: 224, textAlign:'start' }}
              placeholder="CUPOM10OFF"
              value={couponCode}
              
              onChange={handleCouponCodeChange}
              required
            />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <label style={{ marginLeft: 10 }}>Data de Expiração</label>
            <input
              type="TEXT"
              style={{ width: 224,textAlign:'start' }}
              placeholder="ex:30 dias ou 00/00/0000"
              value={expirationDate}
              onChange={handleExpirationDateChange}
            />
          </div>
          <div style={{display:'flex', flexDirection:'column'}}>
            <label style={{ marginLeft: 10 }}>Site do Cupom</label>
            <input
              type="text"
              style={{ width: 224,textAlign:'start' }}
              placeholder="AMAZON"
              value={coupomSite}
              onChange={handleCoupomSiteChange}
              required
            />
          </div>
        </div>
        <div className="text-promo">
          <label>Descrição do Cupom</label>
          <textarea
            value={description}
            onChange={handleDescriptionChange}
            required
          />
        </div>
        <div className="btn-promo" disabled={isLoading}>
          <button type="submit">
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Adicionar Cupom"
            )}
          </button>
        </div>
      </form>
      <ToastContainer position="top-center" />
    </Modal>
  );
};

export default CouponInputPage