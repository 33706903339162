import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './css/timeline.css'
function ValidateProducts() {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  const [productName, setProductName] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productLink, setProductLink] = useState('');
  const [productSiteName, setProductSiteName] = useState('');
  const updateProduct = async (productId) => {
    await axios.put(`api/update-product/${productId}`, { 
      name: productName, 
      image: productImage, 
      description: productDescription, 
      price: productPrice, 
      link: productLink,
      siteName: productSiteName
    },{  headers: {'Content-Type': 'application/json'}});
    await getProducts(); 
    await setSelectedProduct(null)
    
  };

  useEffect(()=>{
    getProducts()
  },[]);

  async function getProducts(){
    const response  = await axios.get(`api/getTimeline`);
    setProducts(response.data);
  }

  const acceptProduct = async (productId) => {
    await axios.put(`api/update-product/${productId}`, { accepted: true },{  headers: {'Content-Type': 'application/json'}});
    getProducts(); 
  };
  
  const rejectProduct = async (productId) => {
    await axios.put(`api/update-product/${productId}`, { accepted: false },{ headers: {'Content-Type': 'application/json'}});
    getProducts(); 
  };

  const editProduct = async (product) => {
    setSelectedProduct(product);
    setProductName(product.name);
    setProductImage(product.image);
    setProductDescription(product.description);
    setProductPrice(product.price);
    setProductLink(product.link);
    setProductSiteName(product.siteName);
  }

  const handleLogin = async () => {
    const {data}  = await axios.post(`api/userAdmin`, { email, password },{ headers: {'Content-Type': 'application/json'}});
    if (data.success) {
      window.sessionStorage.setItem('isAdmin','true')
      checkAdim()
    } else {
      toast.error('Apenas usuários admin podem acessar a página');
    }
  };
  function checkAdim() {
    const exisAdmin = window.sessionStorage.getItem('isAdmin') 

    if(exisAdmin){
      setIsAdmin(true)
    }
  }
 useEffect(()=>{
  checkAdim()
 },[])
  if (isAdmin) {
    return (<>


      <div className="login-form">
        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Senha" />
        <button onClick={handleLogin}>Entrar</button>
      </div>
      <ToastContainer position="top-center" />
      </>
    );
  }
  if( selectedProduct){
    return(
      <div className='edit'>
        <div className='input-edit'>
          <fieldset>
          <label>Nome Produto</label>
          <input type="text" placeholder="Nome do Produto" value={productName} onChange={(e) => setProductName(e.target.value)} />
          </fieldset>
          <fieldset>
          <label>Imagem Produto</label>
          <input type="text" placeholder="URL da Imagem do Produto" value={productImage} onChange={(e) => setProductImage(e.target.value)} />
          </fieldset>
          <fieldset>
          <label>Descrição</label>
          <input type="text" placeholder="Descrição do Produto" value={productDescription} onChange={(e) => setProductDescription(e.target.value)} />
          </fieldset>
          <fieldset>
          <label>Preço</label>
          <input type="text" placeholder="Preço do Produto" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} />
          </fieldset>
          <fieldset>
          <label>Nome do Site</label>
          <input type="text" placeholder="Nome do Site" value={productSiteName} onChange={(e) => setProductSiteName(e.target.value)} />
          </fieldset>
          <fieldset>
          <label>Link</label>
          <input type="text" placeholder="Link do Produto" value={productLink} onChange={(e) => setProductLink(e.target.value)} />
          </fieldset>
          
          <button onClick={() => updateProduct(selectedProduct.id)}>Atualizar Produto</button>
        </div>
      </div>
    )
  }
  return (
    <>
        <div>
            <a href={`/`}>HOME</a>
        </div>
    
    <div className='timeline-content-validate'>
      <div className='timeline-columm'>
        {products?.slice().reverse().filter(product =>( product.accepted == null)).map((product, index) => (
          product.category == null &&
          <div key={index} className='timeline-itens' style={{height:'unset'}}>
              <div className='img-product'>
                <img style={{ objectFit: 'contain' }} src={product.image} alt={product.name} />
              </div>
              <div className='info-product'>
                <p className='product-name'>{product?.name?.length > 20 ? product?.name.substring(0, 50) + '...' : product.name}</p>
                <p className='description collapsed'>{product?.description?.length > 50 ? product.description.substring(0, 50) + '...' : product.description}</p>
                <p className='price'>Preço: {parseFloat(product.price.replace(/\./g, '').replace(/(\d{2})$/, '.$1')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                <div className='interaction' >
                  {product.accepted == false ? <div className='product-reject'><p>PRODUTO REJEITADO</p></div>:
                  <div className='admin-actions'>
                    <button onClick={() => acceptProduct(product.id)}>Aceitar</button>
                    <button onClick={() => rejectProduct(product.id)}>Rejeitar</button>
                    <button onClick={() => editProduct(product)}>Editar</button>
                  </div>
                  }
                  <a href={product.link} target="_blank" rel="noopener noreferrer" className='ir-loja'>{product.siteName}</a>  
                </div>
              </div>
            </div>
        ))}
      </div>
    
    </div>
    </>)
}

export default ValidateProducts;
