import React, { useState, memo, useEffect } from 'react';
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isMobile } from 'react-device-detect';
import { getCoupons, setCoupons } from './IndexDB';
import Modal from 'react-modal';
import CommentBox from './CommentBox';
import axios from 'axios';
import './css/timeline.css';

const CouponItem = memo(({ coupon, openModal, userId }) => {
  const analytics = () => {
    window.dataLayer.push({
      user_id: userId,
      coupon_id: coupon.id,
      event: 'clicklink',
    });
  };

  const [isShareOpen, setIsShareOpen] = useState(false);
  const [showComments, setShowComments] = useState(false);

  const toggleShare = () => {
    setIsShareOpen(!isShareOpen);
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const shareContent = () => {
    if (isMobile) {
      navigator.share({
        title: `Aproveite este cupom incrível => ${coupon.code} => Clique no link e confira `,
        url: `/pdp?couponId=${coupon.id}`,
      })
      .then(() => console.log('Conteúdo compartilhado!'))
      .catch((error) => console.log('Erro ao compartilhar', error));
    } else {
      toggleShare();
    }
  };

  return (
    <div className='timeline-items content-coupom'>
      <div className='coupon-info info-produc' style={{width:"850px"}}>
        <p className='coupon-title'>{coupon?.title}</p>
        <p className='coupon-description'>{coupon?.description}</p>
        <p className='coupon-description'>Site: {coupon?.site}</p>
        {coupon?.expirationDate && <p className='coupon-expiration'>Expira em: {coupon?.expirationDate}</p>}
        <p className='coupon-code'>Código: <b>{coupon?.code}</b>{<CopyToClipboard text={coupon?.code} options={{ message: "Copiado !", debug:true, format:"text" }}>
                  <button style={{ border: 'none', background: 'transparent' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
                    </svg>
                  </button>
                </CopyToClipboard>}</p>
        <div className='interaction'>
          <button className='coments' onClick={() => openModal(coupon.id, coupon.userid)}>
            Ver comentários
          </button>
          <div className='share-dropdown share-balloon'>
            {isShareOpen && (
              <div className='share-dropdown share-balloon'>
                <FacebookShareButton url={`www.indikdo.com.br/pdp?couponId=${coupon.id}`} quote={`Aproveite este cupom incrível => ${coupon.title} => Clique no link e confira `}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <WhatsappShareButton url={`www.indikdo.com.br/pdp?couponId=${coupon.id}`} title={`Aproveite este cupom incrível => ${coupon.title} => Clique no link e confira `}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TwitterShareButton url={`www.indikdo.com.br/pdp?couponId=${coupon.id}`} title={`Aproveite este cupom incrível => ${coupon.title} => Clique no link e confira `}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <CopyToClipboard text={`www.indikdo.com.br/pdp?couponId=${coupon.id}`}>
                  <button style={{ border: 'none', background: 'transparent' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="M440-280H280q-83 0-141.5-58.5T80-480q0-83 58.5-141.5T280-680h160v80H280q-50 0-85 35t-35 85q0 50 35 85t85 35h160v80ZM320-440v-80h320v80H320Zm200 160v-80h160q50 0 85-35t35-85q0-50-35-85t-85-35H520v-80h160q83 0 141.5 58.5T880-480q0 83-58.5 141.5T680-280H520Z" />
                    </svg>
                  </button>
                </CopyToClipboard>
              </div>
            )}
            {!isShareOpen && (
              <button
                onClick={shareContent}
                style={{
                  border: 'none',
                  background: 'transparent',
                  color: '#FFA41C',
                  fontWeight: 'bolder',
                  letterSpacing: '4px',
                }}
              >
                Indike
                <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'>
                  <path fill='#FFA41C' d='M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z' />
                </svg>
              </button>
            )}
          </div>
   
        </div>
      </div>
    </div>
  );
});

function TimelineCupom() {
    const [coupns, setCoupnsState] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [coupomId, setCoupomId] = useState('');
    const [visiblecoupns, setVisiblecoupns] = useState(20);
    const [userId, setUserId] = useState('');
    
    const openModal = (id, userid) => {
      setCoupomId(id);
      setUserId(userid);
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    
    useEffect(()=>{
      const user = JSON.parse(window.sessionStorage.getItem('user'))
  
      if(user){
        setUserId(user.id)
      }
  
    },[userId])
    const loadMore = () => {
      // Increase the number of visible coupns by 20
      setVisiblecoupns((prevVisiblecoupns) => prevVisiblecoupns + 20);
    };
    useEffect(() => {
      async function fetchcoupns() {
        try {
          const storedcoupns = await getCoupons();
          if (storedcoupns.length > 0) {
            setCoupnsState(storedcoupns);
          } else {
            const response = await axios.get('api/getTimelineCoupons');
            await setCoupons(response.data);
            setCoupnsState(response.data);
          }
        } catch (error) {
          console.error('Erro ao buscar produtos:', error);
        }
      }
      fetchcoupns();
    }, []);

    return (
      <div style={{display:'flex', flexDirection:'column'}}>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            ariaHideApp={false}
            onRequestClose={closeModal}
            className={'modal-comments'}
            style={{
              content: {
                margin: 'auto',
                padding: '20px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
          >
            <div style={{display:'flex', justifyContent:'end'}}>
              <button onClick={closeModal} className='material-symbols-outlined'>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                </svg>
              </button>
            </div>
            <CommentBox productId={coupomId} userId={userId}/>
          </Modal>
        )}
        <div className='timeline-content '>
          <div className='timeline-columm'>
            {coupns
              .sort((a, b) => b._ts - a._ts)
              .slice(0, visiblecoupns) // Display only the first `visiblecoupns` items
              .map((coupon, index) => (
                <CouponItem key={coupon.id} coupon={coupon} openModal={openModal} userId={userId} />
              ))}
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center'}}>
        {visiblecoupns < coupns.length && (
          <button onClick={loadMore} className='load-more-button'>
            Ver mais 
          </button>
        )}      
        </div>
      
  
      </div>
    );
  }
  
  export default TimelineCupom;
  
