// @flow
import * as React from 'react';
import './css/sobrenos.css'
import logo from './assets/logo.png';
import { useNavigate } from 'react-router-dom';
export function SobreNos() {
    const navigate = useNavigate()
    function goHome(){
      navigate('/')
      };
  return (
    <div className='central-ajuda'>
     <img src={logo} alt="Logo" className="logo" onClick={goHome} style={{cursor:'pointer'}}/>
      <div className="container">
    <div className="header">
      <h1>Sobre Nós</h1>
    </div>
    <div className="content">
      <p>O Indikdo é uma plataforma social de compras, onde os amantes de economia se reúnem. Todos os dias, milhares de pessoas divulgam ofertas, descontos, erros e avaliações sobre diversos produtos e serviços.</p>
      <p>Criado em 2023, o Indikdo tem a missão de auxiliar as pessoas a gastarem menos e para isso, temos uma grande comunidade que adora descobrir os melhores preços.</p>
      <p>Aqui, qualquer usuário pode compartilhar promoções de lojas seguras e também dar sua opinião sobre um produto ou uma funcionalidade. Os usuários podem recomendar uma promoção quando acharem o preço bom, contribuindo com a comunidade com sua visão.</p>
      <p>Nosso time está sempre de olho, conferindo cada publicação e ajudando você a economizar. Venha fazer parte, é de graça.</p>
    </div>
    <div className='div-btn'>
  <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={goHome}>Voltar</button>
  </div>
  <div className="footer" style={{width:'100%', display:'flex', justifyContent:'center'}}>
    <p>&copy; 2023 Indikdo.com</p>
  </div>
  </div>
    </div>
  );
};