import React from "react";
import './css/footer.css'


function Footer() {
    return(
       <>
        <div className="mainFooter">

                <div className="footer">
                <div className="copy-right">
                    <div className="left">
                        <p style={{fontSize:'20px'}}><b>indikdo © 2023</b></p>
                    </div>
                    <div className="termos termos-foote" >
                                    <a href="/central-ajuda">Central de Ajuda</a>
                                    <a href="/termos-uso">Termos de serviço</a>
                                    <a href="/sobre-nos">Sobre nós</a>
                                    <a href="/politica-privacidade">Política de privacidade</a>
                                </div>
                            <div className="redes">
                                <b>Rede Sociais</b>
                                <div className="right">
                                
                                <a href="https://www.facebook.com/indikdoPage" title="Facebook"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://www.instagram.com/indikdopromo/"title="Instagram"><i className="fab fa-instagram"></i></a>
                                <a href="https://www.twitter.com/indikdoPromo" title="Twitter"><i className="fab fa-twitter"></i></a>

                            </div>
                            </div>            

                 </div>
                 <div style={{display:'flex', justifyContent:'center'}}>
                    <p style={{fontSize:'12px'}}>Quando você compra por meio de links em nosso site, podemos ganhar uma comissão de afiliado sem nenhum custo para você.</p>
                 </div>
            </div>
        </div>
       </> 
    )
}

export default Footer