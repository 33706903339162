import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spinner } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import axios from 'axios';
import './css/addproduct.css'

const tags = ['Crianças & Família','Eletrônicos','Ferramentas','Mídias(Livros,Filmes,Músicas)','Nutrição','Para Casa','Saúde & Beleza','Serviços','Vestuário'];

function ProductInputPage({ isOpen, onRequestClose }) {
  const [isLoading, setLoading] = useState(false);
  const [productUrl, setProductUrl] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTag, setSelectedTag] = useState('')
  const [price, setPrice] = useState('');
  
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setProductUrl(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };
  const handlePriceChange = (e) => {
    let value = e.target.value;
    value = value?.replace(/\,/g, '')?.replace('R$', '');
    setPrice(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(`api/getProductInfo?url=${productUrl}`);
      const getProductInfo = response.data;
      
      let { name, image, siteName } = getProductInfo;
      
      
      const responseCheckPromise = await axios.get(`api/checkCookie`);
  
      const productInfo = {
        name,
        price,
        description,
        image,
        like:0,
        link: productUrl,
        userId: responseCheckPromise.data.user.id,
        tag: selectedTag,
        siteName: siteName
      };
      let productInfoPromise = axios.post(`api/addProducts`, productInfo,{
        headers: { 'Content-Type': 'application/json'}
      });
      
      Promise.all([productInfoPromise, responseCheckPromise]).then(async ([productRes, userRes]) => {
      
        const {user} = userRes.data
      
        const firstComment = {
          id: `comments-${Date.now().toString()}`,
          text: description,
          timestamp: new Date().toISOString(),
          likes: 0,
          userId: user.id, 
          userName: user.nickname, 
          productId: productRes.data.id,
          category: "comments",
        };
      
        await axios.post('api/comments', firstComment, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(() => {
         
        onRequestClose();
        navigate(`/`);
        location.reload(true)
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        })
        .catch((error) => {
          console.error(error);
        });
      });
    

    } catch (error) {
      toast.error('O link inserido da promoção não é válido em nosso site.');
    }
  };

  return (
    <Modal 
    isOpen={isOpen} 
    onRequestClose={onRequestClose}
    ariaHideApp={false}
    className={isMobile ? 'modal-product-mobile' : 'modal-login'}
    style={{
      content: {
        width: '500px',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
      overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    }}>
      <h2>Inserir Promoção</h2>
      <p className='watch-out'><b>ATENÇÃO!!</b> Os produtos levarão no maximo 15 minutos para refletir no site, devido a validação das informações.</p>
      <form onSubmit={handleSubmit}>
      
        <div className='info-promo'>
        <div>
        <label>URL da Promoção</label>
        <input
          type="url"
          value={productUrl}
          placeholder='http://wwww.promoção.com'
          onChange={handleInputChange}
          required
        />
        </div>
       <div>
       <label style={{marginLeft: 10}}>Preço da Promoção</label>
        <input
          type="text"
          step="0.01"
          style={{marginLeft: 10, width: 224}}
          placeholder='100.00'
          value={price}
          onChange={handlePriceChange}
          required
        />
        
        </div>
       </div>
        <div style={{display:'flex',flexDirection:'column'}}>
        <label style={{marginBottom:"10px"}}>Categoria</label>
        <select value={selectedTag} onChange={handleTagChange} required>
          <option value=''>Selecione uma Categoria</option>
          {tags.map((tag) => (
            <option key={tag} value={tag}>{tag}</option>
          ))}
        </select>
        </div>
      
          <div className='text-promo'>
          <label >Descrição da Promoção</label>
        <textarea
          value={description}
          onChange={handleDescriptionChange}
          required
        />
          </div>
        
       <div className='btn-promo' disabled={isLoading}>
       <button type="submit">{isLoading ? <Spinner animation="border" size="sm" /> : 'Adicionar Promoção'}</button>
       </div>
        
      </form>
      <ToastContainer position="top-center" />
    </Modal>
    );
    
}

export default ProductInputPage;
