import React, { useState, useEffect } from 'react';
import './css/comments.css';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;

function CommentBox({ productId, userId, userImage, userName }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isReplying, setIsReplying] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);
  const [userComent, setUserComent] = useState([]);
  const [userAuth, setUserAuth] = useState()
  useEffect(() => {
   

    getCommentsByProducts()
  }, [productId]);
  const getCommentsByProducts = async () => {
   await axios.get(`api/comments?productId=${productId}`)
      .then(async (response) => {
        const userImageMap = {}; 
        await Promise.all(
          response.data.map(async (comment) => {
            const getUserById = await axios.get(
              `api/getUserById?id=${comment.userId}`
            );
            userImageMap[comment.userId] = getUserById.data.user[0].profileImage;
          })
        );
        setComments(response.data);
        setUserComent(userImageMap);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  useEffect(()=>{
    let sessionUser = JSON.parse(window.sessionStorage.getItem('user'))
    if(sessionUser){
      setUserAuth(sessionUser)
    }
    
  },[])
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };
  const handlePostComment = async (parentId) => {
    console.log(userAuth);
    if (newComment.trim() === '') return;
    if (!userAuth) {
      alert('Por favor, faça login para postar um comentário.');
      return;
    }
    const newCommentObject = {
      id: Date.now().toString(),
      text: newComment,
      timestamp: new Date().toISOString(),
      likes: 0,
      userId: userAuth.id,
      userName: userAuth.nickname,
      productId: productId,
      category: "comments",
    };
  
    if (parentId) {
      const parentComment = comments.find((comment) => comment.id === parentId);
      if (parentComment) {
        const respComments = {
          id: Date.now().toString(),
          text: newCommentObject.text,
          respText: parentComment.text,
          respUserName: parentComment.username, 
          timestamp: new Date().toISOString(),
          likes: 0,
          userId: userAuth.id,
          userName: userAuth.nickname,
          productId: productId,
          category: "comments",
        };
        try {
          await axios.post(`api/comments`, respComments, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          });
          getCommentsByProducts()
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      try {
        await axios.post(`api/comments`, newCommentObject, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        getCommentsByProducts()
      } catch (error) {
        console.error(error);
      }
    }
  
    setNewComment('');
    setReplyingTo(null);
    setIsReplying(false);
  }
  
  
  

  const handleReplyClick = (commentId) => {
    setIsReplying(true);
    setReplyingTo(commentId);
  };

  const handleLikeComment = (commentId) => {
    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        comment.likes += 1;
      }
      return comment;
    });
    setComments(updatedComments);
  };

  const handleDeleteComment = (commentId) => {
    axios.delete(`api/comments/${commentId}`)
    .then((response) => {
    })
    .catch((error) => {
      console.error('Erro ao excluir o comentário:', error);
    });
  
  };
  const calculateElapsedTime = (timestamp) => {
    const currentTime = new Date();
    const commentTime = new Date(timestamp);
    const timeDifference = currentTime - commentTime;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} ${days === 1 ? 'dia' : 'dias'} atrás`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hora' : 'horas'} atrás`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'} atrás`;
    } else {
      return `${seconds} ${seconds === 1 ? 'segundo' : 'segundos'} atrás`;
    }
  };
  return (
    <div>
      <h2>Caixa de Comentários</h2>
      <div>
        {comments.map((comment) => (
          comment.respText ? 
          <>
          <div key={comment.id} className="comment-container">
          <div className='resp-test'>
           <b className="comment-text">@{comment.respUserName}</b>
           <p className="comment-text">{comment.respText}</p>
           </div>
              <p className="comment-text">{comment.text}</p>
              <div className="info-comments">
                <small>{calculateElapsedTime(comment.timestamp)}</small>
                {userAuth && userAuth.id === comment.userId && (
  <button onClick={() => handleDeleteComment(comment.id)} className="comment-delete-button">
    Excluir
  </button>
)}
                <div>
      {userComent[comment.userId] && (
        <img
          src={`data:image/jpeg;base64,${userComent[comment.userId]}`}
          alt="Imagem do Usuário"
          className="comment-user-image"
        />
      )}
      {!userComent[comment.userId] && (
        <img
          src="https://i.stack.imgur.com/EYX0L.png"
          alt="Imagem do Usuário"
          className="comment-user-image"
        />
      )}
      
    </div>
   
              </div>
              <div className='user-name-content'><p className="comment-username">@{comment.userName}</p></div>
              {isReplying && replyingTo === comment.id ? (
                <div className="comment-reply-area">
                  <textarea
                    placeholder="Responda a este comentário"
                    value={newComment}
                    onChange={handleCommentChange}
                  ></textarea>
                  <button onClick={() => handlePostComment(comment.id)} className="comment-post-reply-button">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABK0lEQVR4nM2SPUsDQRCGIyLYBQstbITc7ZwQbMwfsBJBre0stPKjFyxt9C8EUiscEW4mQbDKn9BOS1shleUje15OxUSz0cKBt9hl5uGdd7dS+d8FU045FqM5gY78fMlyGZtiIErfKc/jSpS+n4uVrRImxqG/jJR6yEKRUs9NeHclTDkYBUsyFsNgNtxZbKyL0v61M1E2RHkR5Xaly9ynkENgkbGag94acznjySnnSz1mw9ZMmRbjsmh8cMaFU3rFi7fDYO/AKzG6gytnnPm+5S6NMFgBdB12B8ckIxHlUYydcNgPFQ2DJRl7RcitPJ/x1Spy3C9htWsWxLj/+HoBuotvmP9iu5ZSjY3tslE58X9slGop1W9zSDqs5SsrpyH5jYT9CchXo8nMpMOvdOSoep4pdIsAAAAASUVORK5CYII="/>
                  Responder
                  </button>
                </div>
              ) : (
                <button onClick={() => handleReplyClick(comment.id)} className="comment-reply-button">
                <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="20"><path d="M760-200v-160q0-50-35-85t-85-35H273l144 144-57 56-240-240 240-240 57 56-144 144h367q83 0 141.5 58.5T840-360v160h-80Z"/></svg>
                  Responder
                </button>
              )}
            </div></>
          :
          <div key={comment.id} className="comment-container">
            <p className="comment-text">{comment.text}</p>
            <div className="info-comments">
                
              <small>{calculateElapsedTime(comment.timestamp)}</small>
              <div>
      {userComent[comment.userId] && (
        <img
          src={`data:image/jpeg;base64,${userComent[comment.userId]}`}
          alt="Imagem do Usuário"
          className="comment-user-image"
        />
      )}
      {!userComent[comment.userId] && (
        <img
          src="https://i.stack.imgur.com/EYX0L.png"
          alt="Imagem do Usuário"
          className="comment-user-image"
        />
      )}
     
    </div>
    
            </div>
            <div className='user-name-content'> <p className="comment-username">@{comment.userName}</p></div>
            {isReplying && replyingTo === comment.id ? (
              <div className="comment-reply-area">
                <textarea
                  placeholder="Responda a este comentário"
                  value={newComment}
                  onChange={handleCommentChange}
                ></textarea>
                <button onClick={() => handlePostComment(comment.id)} className="comment-post-reply-button" >
                
                  Responder
                  <p></p>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsTAAALEwEAmpwYAAABK0lEQVR4nM2SPUsDQRCGIyLYBQstbITc7ZwQbMwfsBJBre0stPKjFyxt9C8EUiscEW4mQbDKn9BOS1shleUje15OxUSz0cKBt9hl5uGdd7dS+d8FU045FqM5gY78fMlyGZtiIErfKc/jSpS+n4uVrRImxqG/jJR6yEKRUs9NeHclTDkYBUsyFsNgNtxZbKyL0v61M1E2RHkR5Xaly9ynkENgkbGag94acznjySnnSz1mw9ZMmRbjsmh8cMaFU3rFi7fDYO/AKzG6gytnnPm+5S6NMFgBdB12B8ckIxHlUYydcNgPFQ2DJRl7RcitPJ/x1Spy3C9htWsWxLj/+HoBuotvmP9iu5ZSjY3tslE58X9slGop1W9zSDqs5SsrpyH5jYT9CchXo8nMpMOvdOSoep4pdIsAAAAASUVORK5CYII="/>
                </button>
              </div>
            ) : (
              <button onClick={() => handleReplyClick(comment.id)} className="comment-reply-button">
              <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="20"><path d="M760-200v-160q0-50-35-85t-85-35H273l144 144-57 56-240-240 240-240 57 56-144 144h367q83 0 141.5 58.5T840-360v160h-80Z"/></svg>
                  Responder
                
              </button>
            )}
          </div>
        ))}
      </div>
      {isReplying ? null : (
        <div style={{display:'flex', flexDirection:'column'}}>
          <textarea
            placeholder="Digite seu comentário"
            value={newComment}
            onChange={handleCommentChange}
          ></textarea>
          <div style={{display:'flex', justifyContent:'center'}}>
          <button onClick={() => handlePostComment()} className="comment-post-reply-button">
            Postar
          </button>
          </div>
         
        </div>
      )}
    </div>
  );
  
}

export default CommentBox;