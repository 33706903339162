// @flow
import * as React from 'react';
import './css/sobrenos.css'
import logo from './assets/logo.png';
import { useNavigate } from 'react-router-dom';
export function PoliticaPrivacidade() {
    const navigate = useNavigate()
    function goHome(){
      navigate('/')
      };
  return (
    <div className='central-ajuda'>
  <img 
    src={logo} 
    alt="Logo" 
    className="logo" 
    onClick={goHome} 
    style={{cursor:'pointer'}}
  />
  <div className="container">
      <h1>Política de Privacidade</h1>
      <h2>1. Introdução</h2>
      <p>Bem-vindo ao Indikdo. Respeitamos sua privacidade e estamos comprometidos em proteger seus dados pessoais. Esta Política de Privacidade irá informá-lo sobre como lidamos com seus dados pessoais, seus direitos de privacidade e como a lei protege você.</p>
      <h2>2. Dados que coletamos</h2>
      <p>Coletamos as seguintes informações para fornecer nossos serviços:</p>
      <ul style={{ paddingLeft: '30px' }}>
        <li><b>Nome</b></li>
        <li><b>Sobrenome</b></li>
        <li><b>E-mail</b></li>
        <li><b>Telefone</b></li>
        <li><b>Data de nascimento</b></li>
      </ul>
      <h2>3. Como usamos seus dados</h2>
      <p>Usamos seus dados para:</p>
      <ul style={{ paddingLeft: '30px' }}>
        <li><b>Identificar você durante o cadastro</b></li>
        <li><b>Comunicar informações sobre nossos serviços</b></li>
        <li><b>Processar pagamentos de promoções</b></li>
      </ul>
      <h2>4. Seus direitos</h2>
      <p>De acordo com a LGPD, você tem o direito de acessar, corrigir, excluir seus dados, entre outros. Entre em contato conosco para exercer esses direitos.</p>
      <h2>5. Contato</h2>
      <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco em <b>indikdocontato@gmail.com</b>.</p>
    </div>
    <div className='div-btn'>
  <button style={{ caretColor: 'rgb(255,164,28)' }} onClick={goHome}>Voltar</button>
  </div>
  <div className="footer" style={{width:'100%', display:'flex', justifyContent:'center'}}>
    <p>&copy; 2023 Indikdo.com</p>
  </div>
  </div>

  );
};