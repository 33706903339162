import React, { useEffect, useState } from 'react';
import './css/c-indica.css'
import axios from 'axios';
import logo from './assets/logo.png';
import { Modal } from 'react-bootstrap';
import SearchBar from './SearchBar';
import ReactPaginate from 'react-paginate';

const Cindika = () => {
  const [catalog,setCatalog] = useState([])
  const [isModalOpen,setIsModalOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [isAdmin, setIsAdmin] = useState(false);
  const [product, setProduct] = useState({
    sku: '',
    name: '',
    imagem: '',
    link: '',
  });

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  }
  useEffect(() =>{

    getCatalog()
  },[])

  const getCatalog = async() =>{
    await axios.get('/api/getCatalog').then((response) =>{
      setCatalog(response.data)
    })
  }
  const handlePageClick = (data) => {
    let selected = data.selected;
    setCurrentPage(selected);
  };

  const handleInputChange = (event) => {
    setProduct({
      ...product,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/addCatalogProducts', product);
      getCatalog()
      closeModal();
    } catch (error) {
      console.error('Erro ao adicionar produto:', error);
    }
  };
  const handleLogin = async () => {
    const user =  await  JSON.parse(window.sessionStorage.getItem('user'));
    if(user){

      await axios.get(`/api/getUserByEmail?email=${user.email}`).then(async (response) => {
      const email = response.data.user[0].email;
      const password = response.data.user[0].password;
      const {data}  = await axios.post(`/api/userAdmin`, { email, password },{ headers: {'Content-Type': 'application/json'}});
      if (data.success) {
        window.sessionStorage.setItem('isAdmin','true')
        checkAdim()
      } 
    
    });
    }
  }  
    ;
  function checkAdim() {
    const exisAdmin = window.sessionStorage.getItem('isAdmin') 

    if(exisAdmin){
      setIsAdmin(true)
    }
  }
 useEffect(()=>{
  handleLogin()
 },[])
  return (
    <div>
    <div className="cabecalho">
    <img className="logo" src={logo} alt="logo home indikdo" />
      <div className="search-bar" style={{marginTop:"4px"}}>
                         <SearchBar/>
                </div>
      {isAdmin &&<button id="abrir-modal" onClick={openModal}>Adicionar Produto</button>}
     
    </div>
    <Modal
       show={isModalOpen}
        onHide={closeModal}
        ariaHideApp={false}>
           <div class="modal-conteudo">
        <span id="fechar-modal"></span>
        <form id="form-produto" onSubmit={handleSubmit}>
          <h2>Adicionar novo produto</h2>
          <label for="IdIndica">Código do Produto:</label>
          <input type="text" id="IdIndica" name="sku" value={product.sku} onChange={handleInputChange} />
          <label for="nome-produto">Nome do Produto:</label>
          <input type="text" id="nome-produto" name="name" value={product.name} onChange={handleInputChange} />
          <label for="imagem-produto">Imagem do Produto:</label>
          <input type="text" id="imagem-produto" name="imagem" value={product.imagem} onChange={handleInputChange} />
          <label for="link-produto">Link do Produto:</label>
          <input type="text" id="link-produto" name="link" value={product.link} onChange={handleInputChange} />
          <div class="btnModal">
            <button type="button" id="cancelar" onClick={closeModal}>Cancelar</button>
            <button type="submit" id="adicionar">Adicionar</button>
          </div>
        </form>
      </div>

      </Modal>
      <div className="catalogo">
      {catalog.sort((a, b) => b._ts - a._ts).slice(currentPage * 12, (currentPage + 1) * 12).map((catalog) =>(
        <div key={catalog.id} className="produto">
          <img src={catalog.imagem} alt={catalog.name}/>
          <h2>{catalog.name}</h2>
          <p>SKU: <b>{catalog.sku}</b></p>
          <a href={catalog.link}>Ir Para Loja</a>
        </div>
      ))}
      </div>
      <div class="paginacao">
      <ReactPaginate
        previousLabel={'Anterior'}
        nextLabel={'Próximo'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(catalog.length / 10)}
        previousLinkClassName="action"
        nextLinkClassName="action"
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        pageClassName="page"
      />
    </div></div>
  );
}

export default Cindika;
