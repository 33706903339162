// Menu.js
import React, { useState, useEffect } from 'react';
import './css/menu.css';
import axios from 'axios';
import { getProductCount, setProducts, deleteDatabase } from './IndexDB';
import InstructionalVideoModal from './InstructionalVideoModal';
import { isMobile } from 'react-device-detect';

function Menu() {
  const [newProductCount, setNewProductCount] = useState(0);

  const handlePromotionsClick = async () => {
    await deleteDatabase();
    window.localStorage.removeItem('coupom')
    
    const response = await axios.get('api/getTimeline');
    await setProducts(response.data.filter((product) => product.accepted));

    setNewProductCount(0);
    window.location.reload();
  };
  const handleCoupomClick = async () => {
    window.localStorage.setItem('coupom', 'true')
    setNewProductCount(0);
    window.location.reload();
  };

 useEffect(() => {
    const interval = setInterval(async () => {
      const response = await axios.get('api/getTimeline');
      const newCount = response.data.filter((product) => product.accepted).length;
      const oldCount = await getProductCount();
      setNewProductCount(newCount - oldCount);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
    
    
    <header className={`menu ${isMobile && 'menu-mobile'}`}>
      <nav className="nav">
        <ul className="nav-list">
        <li className="nav-item" style={{display:'flex'}}>
      <InstructionalVideoModal/>
        </li>
          <li className="nav-item">
            <button className="nav-item btn-promocoes" onClick={handlePromotionsClick}>
              Promoções{' '}
              {newProductCount > 0 ? (
                <span className="notification-alert">
                  {newProductCount > 9 ? '9+' : newProductCount}
                </span>
              ) : null}
            </button>
          </li>
          <li className="nav-item ">
            <button className="nav-item btn-coupom" onClick={handleCoupomClick}>
              Cupons
            </button>
          </li>
        </ul>
      </nav>
    </header>
    </>
  );
}

export default Menu;
