// indexDB.js
let db;

function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("myDatabase", 1);
        request.onupgradeneeded = function() {
            db = request.result;
            if (!db.objectStoreNames.contains('products')) { 
                db.createObjectStore('products', {keyPath: 'id'}); 
            }
        };
        request.onsuccess = function() {
            db = request.result;
            resolve();
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}
function openDatabaseCoupons() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("myDatabaseCoupons", 1);
        request.onupgradeneeded = function() {
            db = request.result;
            if (!db.objectStoreNames.contains('coupons')) { 
                db.createObjectStore('coupons', {keyPath: 'id'}); 
            }
        };
        request.onsuccess = function() {
            db = request.result;
            resolve();
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}

export async function getProducts() {
    if (!db) {
        await openDatabase();
    }
    return new Promise((resolve, reject) => {
        let transaction = db.transaction("products", "readonly");
        let products = transaction.objectStore("products");
        let request = products.getAll();
        request.onsuccess = function() {
            resolve(request.result);
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}
export async function getCoupons() {
    if (!db) {
        await openDatabaseCoupons();
    }
    return new Promise((resolve, reject) => {
        let transaction = db.transaction("coupons", "readonly");
        let coupons = transaction.objectStore("coupons");
        let request = coupons.getAll();
        request.onsuccess = function() {
            resolve(request.result);
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}
export async function getProductCount() {
    if (!db) {
        await openDatabase();
    }
    return new Promise((resolve, reject) => {
        let transaction = db.transaction("products", "readonly");
        let products = transaction.objectStore("products");
        let request = products.openCursor();
        let count = 0;
        request.onsuccess = function() {
            let cursor = request.result;
            if (cursor) {
                if (cursor.value.accepted) {
                    count++;
                }
                cursor.continue();
            } else {
                resolve(count);
            }
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}

export async function setProducts(data) {
    if (!db) {
        await openDatabase();
    }
    return new Promise((resolve, reject) => {
        let transaction = db.transaction("products", "readwrite");
        let products = transaction.objectStore("products");
        data.forEach((product) => {
            products.put(product);
        });
        transaction.oncomplete = function() {
            resolve();
        };
        transaction.onerror = function() {
            reject(transaction.error);
        };
    });
}
export async function setCoupons(data) {
    if (!db) {
        await openDatabaseCoupons();
    }
    return new Promise((resolve, reject) => {
        let transaction = db.transaction("coupons", "readwrite");
        let coupons = transaction.objectStore("coupons");
        data.forEach((product) => {
            coupons.put(product);
        });
        transaction.oncomplete = function() {
            resolve();
        };
        transaction.onerror = function() {
            reject(transaction.error);
        };
    });
}
export function deleteDatabase() {
    return new Promise((resolve, reject) => {
        if (db) {
            db.close();
        }
        const request = indexedDB.deleteDatabase("myDatabase");
        request.onsuccess = function() {
            db = null;
            resolve();
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}
export function deleteDatabaseCoupoms() {
    return new Promise((resolve, reject) => {
        if (db) {
            db.close();
        }
        const request = indexedDB.deleteDatabase("myDatabaseCoupons");
        request.onsuccess = function() {
            db = null;
            resolve();
        };
        request.onerror = function() {
            reject(request.error);
        };
    });
}